/* eslint-disable no-restricted-syntax, no-continue, no-shadow, no-unused-vars */
/* eslint quote-props: ["error", "consistent"] */

/*
 * Hook into a successful form submission and add GTM
 */
export default async function init() {
  // Find the form element
  const forms = [...document.querySelectorAll('.c-form')];
  for (const form of forms) {
    await formCsrf(form);
    // Attach on-ready listener
    form.addEventListener('freeform-ready', (event) => {
      const { freeform } = event.target;
      // Eventually we want to disable the submit btn
      // but for now we cannot do this before a success response
      // const submitBtn = form.querySelector('button[type=submit]');
      freeform.setOption('successBannerMessage', 'Form submitted successfully');
      freeform.setOption(
        'errorBannerMessage',
        'There were issues with the form. Please review and try again.',
      );
      // re-enabled button on ajax submit
      freeform.addOnAfterAjaxSubmit((event, form, response) => {
        // submitBtn.disabled = false;
        window.location.href = `#${form.id}`;
      });
      // Do something on a successful ajax submit
      freeform.addOnSuccessfulAjaxSubmit((event, form, response) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'form_submission',
          form_handle: form.id,
        });
      });
    });
  }
}
/**
 * Refresh CSRF token and form hash to prevent issues with static caching
 * https://docs.solspace.com/craft/freeform/v3/templating/caching/#static-page-caching-cdn-blitz
 * @param form
 * @returns {Promise<void>}
 */
const formCsrf = async (form) => {
  const pageUrl = '/csrf';
  const formHandle = form.dataset.handle;
  if (!formHandle) {
    console.error('No form handle found');
    return;
  }
  const constructedUrl = `${pageUrl}?form=${formHandle}`;
  try {
    const response = await fetch(
      constructedUrl,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      },
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const result = await response.json();

    if (!result.hash) {
      console.error('No hash found');
      return;
    }

    form.querySelector('input[name=formHash]').value = result.hash;

    if (!result.csrf) {
      console.error('No CSRF token found');
      return;
    }
    const csrf = result.csrf;
    form.querySelector('input[name=' + csrf.name + ']').value = csrf.value;

  } catch (error) {
    console.error(error);
  }
}
